import React from 'react';
import styled from 'styled-components';

import { md } from 'utils/breakpoints';
import TitleName from 'components/UI/Header/TitleName';

import IconGirl from 'images/girl.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media (max-width: ${md}) {
    margin: 0 38px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #3b3516;
  margin: 25px 0 20px;
`;

const Message = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  margin-bottom: 50px;
`;

const Btn = styled.div`
  width: 230px;
  height: 60px;
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Nonetwork = (props) => {
  return (
    <Container>
      <TitleName />
      <img src={IconGirl} />
      <Title>無法連到網路</Title>
      <Message>請檢查網路並再試一次</Message>
      <Btn>了解</Btn>
    </Container>
  );
};

export default Nonetwork;
